const Keyboard = {
    Initialize: () => {
        document.onkeydown = (e) => {
            if(document.activeElement.localName != "body") return;

            const now = new Date().getTime();
            if(state.in.kb.suspended > now) return;

            if(state.in.kb.keys.includes(e.keyCode)) return;
			else state.in.kb.keys.push(e.keyCode);
        };

        document.onkeyup = (e) => {
            if(document.activeElement.localName != "body" && document.activeElement.localName != "input") return;
            
            const now = new Date().getTime();
            if(state.in.kb.suspended > now) return;

            if(!state.in.kb.keys.includes(e.keyCode)) return;
			state.in.kb.keys.splice(state.in.kb.keys.indexOf(e.keyCode), 1);
        };
    },
    Suspend: (seconds) => {
        state.in.kb.suspended = new Date().getTime() + (seconds * 1000);
        state.in.kb.keys = [];
    },
    IsSuspended: () => {
        const now = new Date().getTime();
        if(state.in.kb.suspended > now) {
            state.in.kb.suspended = false;
            return true;
        }
        return false;
    }
};

export default Keyboard;