class Transform {
    static WorldToScreen(wx, wy) {
        wx -= state.camera.loc.X;
        wy -= state.camera.loc.Y;

        let sx = (context.canvas.width/2) + ((wx * state.camera.zoom) * context.canvas.width);
        let sy = (context.canvas.height/2) + ((wy * state.camera.zoom) * (context.canvas.height * (context.canvas.width/context.canvas.height)));

        return {
            X : sx,
            Y : sy
        };
    }

    static MouseToActual(sx, sy) {
        var rect = context.canvas.getBoundingClientRect();
        var mx = (sx - rect.left) / (rect.right - rect.left) * context.canvas.width;
        var my = (sy - rect.top) / (rect.bottom - rect.top) * context.canvas.height;

        return {
            X : mx,
            Y : my
        }
    }

    static ScreenToWorld(sx, sy) {
        var rect = context.canvas.getBoundingClientRect();
        var mx = (sx - rect.left) / (rect.right - rect.left) * context.canvas.width;
        var my = (sy - rect.top) / (rect.bottom - rect.top) * context.canvas.height;

        let wx = ((mx-(context.canvas.width/2)) / context.canvas.width) / state.camera.zoom;
        let wy = ((my-(context.canvas.height/2)) / context.canvas.height) / state.camera.zoom / (context.canvas.width/context.canvas.height);

        wx += state.camera.loc.X;
        wy += state.camera.loc.Y;

        return {
            X : wx,
            Y : wy
        };
    }
}

export default Transform;