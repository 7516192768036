import LifeCycle from "../lifecycle";
import UpdateAtlas from "../../updates/screens/atlasUpdater";
import RenderStructure from "../components/structure";
import RenderStatus from "../components/structure/status";
import OfflineData from "../../data/offline";

const RenderAtlasScreen = () => {
    LifeCycle.PreRender();

    RenderStructure(OfflineData.Positions);

    if(state.sys.status) RenderStatus();

    UpdateAtlas();

    LifeCycle.PostRender();
}

export default RenderAtlasScreen;