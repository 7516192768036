var Canvas = function(canvasTagId)
{
	var tagId = canvasTagId;
	
	function InitializeContext()
	{	
		var canvasTag = document.getElementById(tagId);

		if(canvasTag.getContext) return canvasTag.getContext('2d');
		else
		{
			alert("Failed to initialize rendering context.");
			return;
		}
	}
	
	function RenderDesignCollection(collection, context) 
	{
		var design = collection.Designs.length-1;
	
		for(design; design >= 0; design--)
		{
			var points = collection.Designs[design].Points.length-1;
			
			context.beginPath();
			context.moveTo(collection.Designs[design].Points[points].X, collection.Designs[design].Points[points].Y); 
			
			for(points; points >= 0; points--)
			{
				context.lineTo(collection.Designs[design].Points[points].X, collection.Designs[design].Points[points].Y);
			}
			
			context.fillStyle = collection.Designs[design].FillColor;
			context.fill();
			context.strokeStyle = collection.Designs[design].LineColor;
			context.stroke();
		}
	}

	function RenderFilledCircle(x, y, radius, color, context) {
		context.save();
		context.beginPath();
		context.fillStyle = color;
		context.arc(x, y, Math.abs(radius), 0, 2 * Math.PI);
		context.fill();
		context.restore();
	}
	
	function RenderCircle(x, y, radius, color, width, context)
	{
		context.save();
		context.beginPath();
		
		context.lineWidth = width;
		context.strokeStyle = color;
		context.arc(x, y, Math.abs(radius), 0, 2 * Math.PI);
		
		context.stroke();
		context.restore();
	}
	
	function RenderDesignAsPoints(design, context, options)
	{
		var points = design.Points.length - 1;
		
		for(points; points >= 0; points--)
		{
			context.beginPath();
			context.fillRect(design.Points[points].X, design.Points[points].Y, 1 , 1); 
			context.strokeStyle = "#000";
			context.stroke();
		}
	}
	
	function RenderDesignAsCircles(design, radius, context, color)
	{
		var points = design.Points.length - 1;
		
		for(points; points >= 0; points--)
		{
			context.beginPath();
			context.arc(design.Points[points].X, design.Points[points].Y, radius, 0, 2 * Math.PI); 
			context.strokeStyle = color;
			context.stroke();
		}
	}

	function RenderDesign(design, context, options)
	{
		var points = design.Points.length-1;
		
		context.beginPath();
		context.moveTo(design.Points[points].X, design.Points[points].Y); 
		
		for(points; points >= 0; points--)
		{
			context.lineTo(design.Points[points].X, design.Points[points].Y);
		}
		
		context.fillStyle = design.FillColor;
		context.fill();
		context.strokeStyle = design.LineColor;
		context.stroke();
	}
	
	function ClearCanvas(context, width, height)
	{
		context.clearRect (0 , 0 , width , height);
	}
	
	function FadeCanvas(context, width, height, rgbaColor)
	{
		context.beginPath();
		context.fillStyle = rgbaColor;
		context.fillRect(0,0,width,height);  
		context.stroke();
	}
	
	function JsonToPointArray(jsonShape)
	{
		var point = jsonShape.Points.length-1;
		
		var resultPoints = [];
		
		for(point; point >= 0; point--)
		{
			resultPoints[point] = new Point(jsonShape.Points[point].X, jsonShape.Points[point].Y);
		}
		
		return resultPoints;
	}
	
	return {
		Clear : ClearCanvas,
		Fade : FadeCanvas,
		Initialize : InitializeContext,
		RenderCollection : RenderDesignCollection,
		Render : RenderDesign,
		RenderCircles : RenderDesignAsCircles,
		RenderPoints : RenderDesignAsPoints,
		JsonToPoints : JsonToPointArray,
		Circle : RenderCircle,
		FilledCircle : RenderFilledCircle
	};
}

var Point = function(_x, _y, _z) {
	var local_x = _x;
	var local_y = _y;
	var local_z = _z;
	
	function RotatePoint(amount) // based on right handed coordinate system.
	{
		if((local_x == 0 && local_y == 0) || amount == 0) 
			return new Point(local_x, local_y);
		
		var theta = (amount * Math.PI);
		var new_x = local_x * Math.cos(theta) - local_y * Math.sin(theta);
		var new_y = local_x * Math.sin(theta) + local_y * Math.cos(theta);
	
		return new Point(new_x, new_y);
	}
	
	function TranslatePoint(distanceX, distanceY)
	{
		var new_x = local_x + distanceX;
		var new_y = local_y + distanceY;
		
		return new Point(new_x, new_y);
	}
	
	function ScalePoint(amountX, amountY)
	{
	
		var new_x = local_x * amountX;
		var new_y = local_y * amountY;
	
		return new Point(new_x, new_y);
	}
	
	function DistanceToOrigin() {
		return Math.sqrt((local_x * local_x)+(local_y * local_y));
	}

	function DistanceToPoint(point) {
		let dx = local_x - point.X;
		let dy = local_y - point.Y;

		let h = (dx * dx) + (dy * dy);

		return Math.sqrt(h);
	}
	
	function OrientationOfPoint()
	{
		return Math.atan2(local_x, local_y);
	}
	
	function OrientationOfPointDegrees()
	{
		var degrees = Math.atan2(local_x, local_y) * 180 / Math.PI;
		
		if(degrees >= 360){ degrees -= 360; }
		if(degrees < 0) { degrees += 360; }
		
		return degrees;
	}
	
	function LocationOfPoint()
	{
		return new Point(local_x, local_y);
	}
	
	function ConvertToUnitCoordinates(width, height)
	{
		return new Point((local_x/width)-0.5, (local_y/height)-0.5);
	}
	
	function ConvertToNativeCoordinates(width, height)
	{
		return new Point((local_x + 0.5) * width, (local_y + 0.5) * height);
	}

	function CompareTwoPoints(point) {
		if(local_x == point.X && local_y == point.Y) return true;
	}

	function JitterPoint(scalarVertice) {
		return new Point(local_x + (Math.random() * ((2 * scalarVertice.X)-scalarVertice.X)), 
		                 local_y + (Math.random() * ((2 * scalarVertice.Y)-scalarVertice.Y)));
	}
	
	return {
		Rotate : RotatePoint,
		Translate : TranslatePoint,
		Scale : ScalePoint,
		Distance : DistanceToPoint,
		OriginDistance : DistanceToOrigin,
		Orientation : OrientationOfPoint,
		OrientationInDegrees : OrientationOfPointDegrees,
		Location : LocationOfPoint,
		X : local_x,
		Y : local_y,
		Z : local_z,
		ToUnit : ConvertToUnitCoordinates,
		ToNative : ConvertToNativeCoordinates,
		Compare : CompareTwoPoints,
		Jitter : JitterPoint
	};
}

var Design = function(points)
{
	var pointCollection = [];
	
	Construct(points);
	
	var localLineColor = "#000";
	var localFillColor = "#fff";

	function Construct(points)
	{
		var point = points.length - 1;

		for(point; point >= 0; point--)
		{
			pointCollection[point] = new Point(points[point].X, points[point].Y);
		}
	}
	
	function TranslatePointObject(amountX, amountY)
	{
		var point = pointCollection.length - 1;
		var result = [];
		
		for(point; point >= 0; point--)
		{
			result[point] = pointCollection[point].Translate(amountX, amountY);
		}
		
		var finalObject = new Design(result);
		
		finalObject.LineColor = this.LineColor;
		finalObject.FillColor = this.FillColor;
		
		return finalObject;
	}
	
	function RotatePointObject(amount)
	{
		var point = pointCollection.length-1;
		var result = [];
		
		for(point; point >= 0; point--)
		{
			result[point] = pointCollection[point].Rotate(amount);
		}
		
		var finalObject = new Design(result);
		
		finalObject.LineColor = this.LineColor;
		finalObject.FillColor = this.FillColor;
		
		return finalObject;
	}
	
	function ScalePointObject(amountX, amountY)
	{
		var point = pointCollection.length-1;
		var result = [];
		
		for(point; point >= 0; point--)
		{
			result[point] = pointCollection[point].Scale(amountX, amountY);
		}
		
		var finalObject = new Design(result);
		
		finalObject.LineColor = this.LineColor;
		finalObject.FillColor = this.FillColor;
		
		return finalObject;
	}
	
	function AddPoints(points)
	{
		var point = (points.length - 1)
	
		var result = [];
		
		for(point; point >= 0; point--)	result.push(points[point]);
		
		point = pointCollection.length - 1;
		
		for(point; point >= 0; point--) result.push(pointCollection[point]);
		
		var finalObject = new Design(result);
		
		finalObject.LineColor = this.LineColor;
		finalObject.FillColor = this.FillColor;
		
		return finalObject;				
	}
	
	return {
		Points : pointCollection,
		Translate : TranslatePointObject,
		Rotate : RotatePointObject,
		Scale : ScalePointObject,
		Add : AddPoints,
		LineColor : localLineColor,
		FillColor : localFillColor
	};
}

var DesignCollection = function(designs)
{
	var designCollection = [];
	
	Construct(designs)

	function Construct(designs) {
		designCollection = designs;
		
		//var design = designs.length-1;
		
		//for(design; design >= 0; design--)
		//{
		//	designCollection[design] = new Design(designs[design].Points);
		//}
	}
	
	function TranslateDesignCollection(amountX, amountY)
	{
		var design = designs.length - 1;
		
		for(design; design >= 0; design--) {
			
			designs[design] = designs[design].Translate(amountX, amountY);
			
		}
		
		return new DesignCollection(designs);
	}
	
	function RotateDesignCollection(amount)
	{
		var design = designs.length - 1;
		
		for(design; design >= 0; design--) {
			
			designs[design] = designs[design].Rotate(amount);
			
		}
		
		return new DesignCollection(designs);
	}
	
	function ScaleDesignCollection(amountX, amountY)
	{
		var design = designs.length - 1;
		
		for(design; design >= 0; design--) {
			
			designs[design] = designs[design].Scale(amountX, amountY);
			
		}
		
		return new DesignCollection(designs);
	}
	
	return {
		Designs : designCollection,
		Translate : TranslateDesignCollection,
		Rotate : RotateDesignCollection,
		Scale : ScaleDesignCollection
	};
}

var Motion = function()
{
	function Smooth(start, end, current)
	{
		if(start == end) return end;
	
		if (start < end && start + current >= end) return end;
		else if(start > end && start - current <= end) return end;
	
		var point = new Point(-1, 0);
		
		var length = end - start;
		var progress = (current/length);
		
		var remappedCurrent = (point.Rotate(progress).Translate(1, 0).X) / 2;
		
		return start + (remappedCurrent * length);
	}
	
	function EaseIn(start, end, current)
	{
		if(start == end) return end;
	
		if (start < end && start + current >= end) return end;
		else if(start > end && start - current <= end) return end;
	
		var point = new Point(-1, 0);
		
		var length = end - start;
		var progress = (current/length);
		
		var remappedCurrent = (point.Rotate(progress / 2).Translate(1, 0).X);
		
		return start + (remappedCurrent * length);
	}
	
	function EaseOut(start, end, current)
	{
		if(start == end) return end;
	
		if (start < end && start + current >= end) return end;
		else if(start > end && start - current <= end) return end;

		var point = new Point(0, 1);
		
		var length = end - start;
		var progress = (current/length);
		
		var remappedCurrent = 1 - (point.Rotate(progress / 2).Translate(1, 0).X);
		
		return start + (remappedCurrent * length);
	}
	
	return {
		Smooth : Smooth,
		EaseIn : EaseIn,
		EaseOut: EaseOut,
		EaseInOut : Smooth
	};
}

export { Canvas, Point, Design };
