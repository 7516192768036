import Keyboard from "./controllers/keyboard";
import Mouse from "./controllers/mouse";
import Renderer from "./renderers/renderer";
import State from "./state";

State.Initialize();

Mouse.Initialize();
Keyboard.Initialize();

Renderer.Initialize();

Renderer.Render();