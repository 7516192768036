const OfflineData = {
    Positions: [
        { // 0
            name: 'Standing',
            transitions: [
                {
                    name: 'Double Leg Takedown',
                    position: 1
                },
                {
                    name: 'Single Leg Takedown',
                    position: 1
                },
                {
                    name: 'Osotogari',
                    position: 6
                },
                {
                    name: 'Firemans Carry',
                    position: 7
                },
                {
                    name: 'O Goshi',
                    position: 6
                },
                {
                    name: 'Foot Sweep',
                    position: 1
                },
                {
                    name: 'Seoi Nage',
                    position: 6
                },
                {
                    name: 'Tomoe Nage',
                    position: 8
                },
                {
                    name: 'Pull Guard',
                    position: 1
                },
                {
                    name: 'Sit Down',
                    position: 2
                }
            ],
            submissions: [
                {
                    name: 'Flying Armbar'
                },
                {
                    name: 'Guillotine'
                },
                {
                    name: 'Flying Triangle Choke'
                }
            ],
        },
        { // 1
            name: 'Closed Guard',
            transitions: [
                {
                    name: 'Stand Up',
                    position: 0
                },
                {
                    name: 'Closed Guard Break',
                    position: 2
                },
                {
                    name: 'Scissor Sweep',
                    position: 8
                },
                {
                    name: 'Hip Bump Sweep',
                    position: 8
                },
                {
                    name: 'Elevator Sweep',
                    position: 8
                },
                {
                    name: 'Lumberjack Sweep',
                    position: 8
                },
                {
                    name: 'Flower Sweep',
                    position: 8
                },
                {
                    name: 'Pendulum Sweep',
                    position: 8
                },
                {
                    name: 'Stack/Double Under Pass',
                    position: 6
                },
                {
                    name: 'Over/Under Pass',
                    position: 6
                },
                {
                    name: 'Sao Paulo Pass',
                    position: 6
                },
                {
                    name: 'Body Lock Pass',
                    position: 6
                }
            ],
            submissions: [
                {
                    name: 'Same Side Kimura'
                },
                {
                    name: 'Cross Side Kimura'
                },
                {
                    name: 'Collar Choke'
                },
                {
                    name: 'Armbar'
                },
                {
                    name: 'Triangle Choke'
                }
            ]
        },
        { // 2
            name: 'Open Guard',
            transitions: [
                {
                    name: 'Toreando Pass',
                    position: 6
                },
                {
                    name: 'Leg Drag Pass',
                    position: 6
                },
                {
                    name: 'Back Step Pass',
                    position: 6
                },
                {
                    name: 'Go to De La Riva',
                    position: 15
                },
                {
                    name: 'Go to Spider Guard',
                    position: 16
                }
            ],
            submissions: []
        },
        { // 3
            name: 'Half Guard',
            transitions: [
                {
                    name: 'Old Skool',
                    position: 6
                },
                {
                    name: 'Plan B/Moose Roll',
                    position: 6
                },
                {
                    name: 'Walk up',
                    position: 8
                },
                {
                    name: 'Cradle Pass',
                    position: 6
                }
            ],
            submissions: []
        },
        { // 4
            name: 'Deep Half',
            transitions: [
                {
                    name: 'Homer Simpson',
                    position: 10
                }
            ],
            submissions: []
        },
        { // 5
            name: 'Quarter Guard',
            transitions: [
                {
                    name: 'Hip Switch',
                    position: 13
                },
                {
                    name: 'Tom Foolery',
                    position: 8
                }
            ],
            submissions: []
        },
        { // 6
            name: 'Side Control',
            transitions: [
                {
                    name: 'Shrimp to Reguard',
                    position: 1
                },
                {
                    name: 'Ghost Escape',
                    position: 2
                },
                {
                    name: 'Step Over to Mount',
                    position: 8
                },
                {
                    name: 'Jump to Knee on Belly',
                    position: 12
                },
                {
                    name: 'Circle to North/South',
                    position: 7
                }
            ],
            submissions: []
        },
        { // 7
            name: 'North/South',
            transitions: [
                {
                    name: 'Sit up back take',
                    position: 10
                },
                {
                    name: 'Circle to Side Control',
                    position: 6
                }
            ],
            submissions: []
        },
        { // 8
            name: 'Mount',
            transitions: [
                {
                    name: 'Upa',
                    position: 1
                },
                {
                    name: 'Elbow Knee Escape',
                    position: 5
                },
                {
                    name: 'Jump to Knee on Belly',
                    position: 12
                },
                {
                    name: 'Back to Sidecontrol',
                    position: 6
                }
            ],
            submissions: [
                {
                    name: 'Triangle'
                },
                {
                    name: 'Americana'
                },
                {
                    name: 'Armbar'
                },
                {
                    name: 'Cross Collar Choke'
                },
                {
                    name: 'Ezekial Choke'
                },
                {
                    name: 'Arm Triangle'
                }
            ]
        },
        { // 9
            name: '50/50',
            transitions: [],
            submissions: []
        },
        { // 10
            name: 'Back Control',
            transitions: [
                {
                    name: 'Arm Over Escape',
                    position: 1
                },
                {
                    name: 'Flop and Pin Escape',
                    position: 6
                }
            ],
            submissions: [
                {
                    name: 'Rear Naked Choke'
                },
                {
                    name: 'Armbar'
                }
            ]
        },
        { // 11
            name: 'Kesa Getame',
            transitions: [],
            submissions: []
        },
        { // 12
            name: 'Knee on Belly',
            transitions: [
                {
                    name: 'Go to Mount',
                    position: 8
                },
                {
                    name: 'Go to Side Control',
                    position: 6
                }
            ],
            submissions: []
        },
        { // 13
            name: 'Dog Fight',
            transitions: [],
            submissions: []
        },
        { // 14
            name: 'Turtle',
            transitions: [],
            submissions: []
        },
        { // 15
            name: 'De La Riva',
            transitions: [
                {
                    name: 'Back Step Pass',
                    position: 6
                },
                {
                    name: 'Ragdoll',
                    position: 10
                }
            ],
            submissions: []
        },
        { // 16
            name: 'Spider Guard',
            transitions: [
                {
                    name: 'Puppet Sweep',
                    position: 6
                },
                {
                    name: 'Padilla Sweep',
                    position: 6
                }
            ],
            submissions: []
        }
    ],
}

export default OfflineData;