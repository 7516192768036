import Config from '../../configurations/config';

const RenderText = (txt, loc, frame, 
    ops = { 
        color: Config.Default.Text.Color, 
        font: Config.Default.Text.Font, 
        align: Config.Default.Text.Alignment,
        rate: 1,
        alpha: 1
    }) => {
    if(txt === undefined) return;
    let lines = txt.substr(0, frame * ops.rate).split('\n');

    context.save();
    
        context.globalAlpha = ops.alpha;
        context.imageSmoothingEnabled = false;
        context.fillStyle = ops.color;
        context.font = ops.font;
        context.textAlign = ops.align;
        context.beginPath();

        let l = lines.length;
        while(l --> 0) context.fillText(lines[l], loc.X, loc.Y + (l * 20));
    
    context.restore();
}

export default RenderText;