import UpdateCamera from "../cameraUpdater";
import Mouse from "../../controllers/mouse";
import Transform from "../../utilities/transform";
import { Point } from "../../utilities/pointDesign";

const UpdateAtlas = () => {
    const mlocLeft = Mouse.Check(0);
    const mlocRight = Mouse.Check(2);

    if(state.in.mouse.wheel !== null) {
        state.camera.target.zoom += (state.in.mouse.wheel*.25);
    }
        
    if(mlocLeft) {
        state.camera.target.loc = Transform.ScreenToWorld(state.in.mouse.loc.X, state.in.mouse.loc.Y);

        let indexCount = state.in.selections.positions.length;
        while(indexCount --> 0) {
            if(!state.in.selections.positions[indexCount]) continue;
            let selection = null;
            const positions = state.in.selections.positions[indexCount];
            positions.forEach((v, i) => {
                const position = new Point(v.split(',')[0], v.split(',')[1]);
                if(position.Distance({ X: state.in.mouse.loc.X, Y: state.in.mouse.loc.Y }) < (40 * v.split(',')[2])) {
                    selection = indexCount;
                    state.user.history.positions.push(indexCount);
                    state.user.history.transitions.push(i);
                }
                console.log(state.user.history.positions);
                console.log(state.user.history.transitions);
            })

            if(selection !== null) {
                state.user.selected.position = selection;
                state.camera.target.loc.X += .15;
                state.camera.target.loc.Y = 0;
                break;
            }
        }
    }

    if(mlocRight) {
        state.camera.target.loc = Transform.ScreenToWorld(state.in.mouse.loc.X, state.in.mouse.loc.Y);
    }

    UpdateCamera();
}

export default UpdateAtlas;