import Config from "../configurations/config";

const UpdateCamera = () => {
    if(state.camera.zoom !== state.camera.target.zoom) {
        const amount = Config.Camera.ZoomIncrement;

        if(state.camera.target.zoom > state.camera.zoom) state.camera.zoom += amount;
        else state.camera.zoom -= amount;
        if(Math.abs(state.camera.target.zoom - state.camera.zoom) < amount) state.camera.zoom = state.camera.target.zoom;
    }

    const interpolation = Config.Camera.Interpolation;
    const difference = {
        X: state.camera.loc.X - state.camera.target.loc.X,
        Y: state.camera.loc.Y - state.camera.target.loc.Y
    }
    const increment = {
        X : difference.X / (interpolation / state.camera.zoom),
        Y : difference.Y / (interpolation / state.camera.zoom)
    }

    state.camera.loc.X = state.camera.loc.X - increment.X;
    state.camera.loc.Y = state.camera.loc.Y - increment.Y;

}

export default UpdateCamera;