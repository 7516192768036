import { Point } from "../../../utilities/pointDesign";
import RenderNode from "./node";

const RenderHeirarchy = (roots, dataSet) => {
    let count = roots.length;
    while (count --> 0) {
        let loc = new Point(.25, 0).Rotate(((1 / roots.length) * count) * 2);
        if (roots.length === 1) loc = new Point(0, 0);

        RenderNode(loc, count, dataSet);
    }
}

export default RenderHeirarchy;