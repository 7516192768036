import { Canvas } from '../utilities/pointDesign';
import Config from '../configurations/config';
import ScreenRenderers from './screens/screenRenderers';

class Renderer {
    static Initialize() {
        window.canvas = Canvas(Config.CanvasTag);
        window.context = window.canvas.Initialize();
        window.context.imageSmoothingEnabled = true;

        const resize = () => {
            context.canvas.width = window.innerWidth;
            context.canvas.height = window.innerHeight;
        }
        resize();

        addEventListener("resize", (event) => {
            resize();
        });

        const urlParams = new URLSearchParams(window.location.search);
        const node = urlParams.get('node');
        if(node !== null) state.user.history.positions.push(node);
        else state.user.history.positions.push(0);

        state.camera.target.zoom = 2.75;
    }

    static Render() {
        ScreenRenderers[window.state.screen]();
    }
}

export default Renderer;