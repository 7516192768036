import seedrandom from 'seedrandom';

class PseudoRandom {
    static Generate(seed) {
        let rng = seedrandom(seed);
        return rng();
    }

    static GenerateArray(seed, length) {
        let result = [];
        const end = seed - length;
        while(seed --> end) {
            result.push(this.Generate(seed));
        }
        return result;
    }

    static PickOneFromLookUp(array) {
        return array[(Math.floor(s.lookup.random.next() * array.length))];
    }
}

export default PseudoRandom;