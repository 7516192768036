import { Point } from "../../../utilities/pointDesign";
import Transform from "../../../utilities/transform";
import RenderText from "../text";
import Config from "../../../configurations/config";

const RenderNode = (loc, index, dataSet, scale=1.5, level=0) => {
    if(level > Config.Limit) return;

    context.save();

        const screenLoc = Transform.WorldToScreen(loc.X, loc.Y);
        if(level === 2) {
            if(!state.in.selections.positions[index]) state.in.selections.positions[index] = [];
            if(!state.in.selections.positions[index].includes(`${screenLoc.X},${screenLoc.Y}`)) state.in.selections.positions[index].push(`${screenLoc.X},${screenLoc.Y},${scale}`);
        }
        const obj = dataSet[index];
        const frame =(state.sys.anim.frame / 2) - level * 20;

        let size = 20;
        // let distance = new Point(state.camera.loc.X, state.camera.loc.Y).Distance(new Point(loc.X, loc.Y));
        // context.globalAlpha = (1 - (distance * 6)) < 0 ? 0 : (1 - (distance * 6));

        if(level > 2) context.globalAlpha = .9/level;
        
        // Render transitions
        obj.transitions.forEach((v, i) => {
            const siblingLoc = new Point(.06 * scale, 0).Rotate((i * (1/obj.transitions.length) * 2) + state.sys.anim.frame / 5000 * scale).Translate(loc.X, loc.Y);
            const transitionLoc = new Point(.035 * scale, 0).Rotate((i * (1/obj.transitions.length) * 2) + state.sys.anim.frame / 5000 * scale).Translate(loc.X, loc.Y);
            let transitionScreenLoc = Transform.WorldToScreen(transitionLoc.X, transitionLoc.Y);
            let siblingScreenLoc = Transform.WorldToScreen(siblingLoc.X, siblingLoc.Y);

            if(i === 0) {
                const circleRadius = new Point(transitionScreenLoc.X, transitionScreenLoc.Y).Distance(new Point(screenLoc.X, screenLoc.Y));
                window.canvas.Circle(screenLoc.X, screenLoc.Y, circleRadius, 'rgba(0, 255, 0, .25)', 1, window.context);
            }

            if(level !== Config.Limit) {
                context.beginPath();
                context.strokeStyle = `rgba(128, 128, 128, ${context.globalAlpha/2})`;
                context.moveTo(screenLoc.X, screenLoc.Y);
                context.lineTo(siblingScreenLoc.X, siblingScreenLoc.Y);
                context.stroke();
            }
            
            window.canvas.FilledCircle(transitionScreenLoc.X, transitionScreenLoc.Y, ((size/6) - 1) * state.camera.zoom * scale, '#00ff00', window.context);
            //state.in.selections.transitions[index].push(i);
            RenderText(`${v.name}`, { X:transitionScreenLoc.X + 12, Y: transitionScreenLoc.Y + 3 }, frame, {
                color: level === 1 ? '#000000' : '#777777', 
                font: Config.Default.Text.FontSmall, 
                align: 'left',
                rate: 1,
                alpha: context.globalAlpha
            });
            RenderNode(siblingLoc, v.position, dataSet, scale/2, level+1);

        });

        // Render submissions
        // obj.submissions.forEach((v, i) => {
        //     const subLoc = new Point(.0175 * scale, 0).Rotate(((i * (1/obj.submissions.length) * 2) + (.5 * (1/obj.transitions.length) * 2)) + state.sys.anim.frame / 5000 * scale).Translate(loc.X, loc.Y);
        //     let subScreenLoc = Transform.WorldToScreen(subLoc.X, subLoc.Y);

        //     context.beginPath();
        //         context.strokeStyle = `rgba(128, 0, 0, ${context.globalAlpha/2})`;
        //         context.moveTo(screenLoc.X, screenLoc.Y);
        //         context.lineTo(subScreenLoc.X, subScreenLoc.Y);
        //     context.stroke();

        //     window.canvas.FilledCircle(subScreenLoc.X, subScreenLoc.Y, ((size/12) - 1) * state.camera.zoom * scale, '#ff0000', window.context);
        //     state.in.selections.submissions[i] = subScreenLoc;

        //     RenderText(`${v.name}`, { X:subScreenLoc.X + 12, Y: subScreenLoc.Y + 3 }, frame, {
        //         color: '#ff0000', 
        //         font: Config.Default.Text.FontSmall, 
        //         align: 'left',
        //         rate: 1,
        //         alpha: context.globalAlpha
        //     });
        // });

        if(level === 2) window.canvas.Circle(screenLoc.X, screenLoc.Y, size * state.camera.zoom * scale, '#7777ff', 2 * state.camera.zoom * scale, window.context);
        else {
            window.canvas.FilledCircle(screenLoc.X, screenLoc.Y, ((size * state.camera.zoom) - (2 * state.camera.zoom)) * scale, '#7777ff', window.context);
            window.canvas.Circle(screenLoc.X, screenLoc.Y,  ((size * state.camera.zoom) - (2 * state.camera.zoom)) * scale, '#3333ff', 1 * state.camera.zoom * scale, window.context);
        }

        RenderText(`${obj.name}`, { X:screenLoc.X, Y: screenLoc.Y + 3 }, frame, {
            color: '#000000', 
            font: Config.Default.Text.FontLarge, 
            align: 'center',
            rate: 1,
            alpha: context.globalAlpha
        });

    context.restore();
}

export default RenderNode;