import RenderHeirarchy from "./structure/heirarchy";
import RenderHistory from "./structure/history";

const RenderStructure = (objs, node = 0) => {
    if(state.user.history.positions.length > 0) {
        const history = state.user.history.positions;
        RenderHistory(history, objs);
        return;
    }
    
    RenderHeirarchy([objs[0]], objs);
}

export default RenderStructure;