import PseudoRandom from "./utilities/pseudoRandom";

const State = {
    Initialize: () => {
        window.state = {
            screen: 0,
            user: {
                selected: {
                    position: null,
                    transition: null,
                    submmission: null
                },
                hover: {
                    position: null,
                    transition: null,
                    submission: null
                },
                history: {
                    positions: [],
                    transitions: []
                }
            },
            in: {
                selections: {
                    positions: [],
                    transitions: [],
                    submissions: []
                },
                kb: {
                    suspended: null,
                    keys: []
                },
                mouse: {
                    click: null,
                    loc: {},
                    buttons: [],
                    wheel: null
                },
            },
            out: {
                now: 0
            },
            sys: {
                status: false,
                anim: {
                    frame: 0,
                }
            },
            camera : {
                zoom : 1,
                loc : { X : 0, Y : 0 },
                target : { 
                    loc : { X : 0, Y : 0 },
                    zoom : 1,
                },
            },
            lookup : {
                random : {
                    numbers : PseudoRandom.GenerateArray(new Date().getTime(), 8192),
                    current : 0,
                    next : () => { 
                        s.lookup.random.current++;
                        if(s.lookup.random.current >= s.lookup.random.numbers.length) s.lookup.random.current = 0;
                        return s.lookup.random.numbers[s.lookup.random.current];
                    }
                },
                casualRandom : {
                    numbers : PseudoRandom.GenerateArray(new Date().getTime()+1000, 8192),
                    current : 0,
                    next : () => { 
                        s.lookup.casualRandom.current++;
                        if(s.lookup.casualRandom.current >= s.lookup.casualRandom.numbers.length) s.lookup.casualRandom.current = 0;
                        return s.lookup.casualRandom.numbers[s.lookup.casualRandom.current];
                    }
                }
            },
        }
    }
}

export default State;