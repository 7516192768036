import RenderText from '../text';
import Transform from '../../../utilities/transform';

const RenderStatus = () => {
    RenderText(`mouse(screen) X: ${state.in.mouse.loc.X} Y: ${state.in.mouse.loc.Y}`, { X: 10, Y: 10 }, state.sys.anim.frame);

    let loc = Transform.ScreenToWorld(state.in.mouse.loc.X, state.in.mouse.loc.Y);

    RenderText(`mouse(world)`, { X: 10, Y: 20 }, state.sys.anim.frame);
    RenderText(`X: ${loc.X}`, { X: 10, Y: 30 }, state.sys.anim.frame);
    RenderText(`Y: ${loc.Y}`, { X: 10, Y: 40 }, state.sys.anim.frame);
}

export default RenderStatus;