const Mouse = {
    Check: (which = 0) => {
        if(state.out.now - state.in.mouse.buttons[which] < 16) return state.in.mouse.loc;
        return null;
    },
    Clear: () => {
        state.in.mouse.click = null;
        state.in.mouse.button = [];
        state.in.mouse.wheel = null;
    },
    Initialize: () => {
        window.oncontextmenu = (e) => {
            const now = new Date().getTime();
            state.in.mouse.click = now;
            state.in.mouse.buttons[e.button] = now;
            return false;
        }

        document.getElementById("ViewPort").onmousemove = (e) => {
            state.in.mouse.loc = { X: e.pageX, Y: e.pageY };
        }

        document.getElementById("ViewPort").onclick = (e) => {
            const now = new Date().getTime();
            state.in.mouse.click = now;
            state.in.mouse.buttons[e.button] = now;
        }

        document.getElementById("ViewPort").onwheel = function (e) {
            if (e.wheelDelta > 0 || e.deltaY < 0) { // firefox has rogue specification for handling mouse wheel input.
                state.in.mouse.wheel = 1;
            }
            else {
                state.in.mouse.wheel = -1;
            }
        };
    }
};

export default Mouse;