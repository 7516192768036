import RenderNode from "./node";
import { Point } from "../../../utilities/pointDesign";
import Transform from "../../../utilities/transform";
import RenderText from "../text";
import Config from "../../../configurations/config";

const RenderHistory = (history, dataSet) => {

    context.save();
        context.beginPath();
            context.globalAlpha = .15;
            context.lineWidth = 8;
            context.setLineDash([8, 16]);
            const begin = Transform.WorldToScreen(.01, 0);
            const end = Transform.WorldToScreen((state.user.history.positions.length-1) * .15, 0);
            context.moveTo(begin.X, begin.Y);
            context.lineTo(end.X, end.Y);
        context.stroke();
    context.restore(); 

    // state.user.history.transition.forEach((v, i) => {
    //     const screenLoc = Transform.WorldToScreen(.055 + (i * .15) , -.001);

    //     RenderText(transitionTitle, { X: screenLoc.X, Y: screenLoc.Y }, 100, {
    //         color: '#00ff00', 
    //         font: Config.Default.Text.FontLarge, 
    //         align: 'center',
    //         rate: 1,
    //         alpha: 1
    //     });
    // });

    let positionCount = history.length;
    while(positionCount --> 0) {
        let loc = new Point(0 + positionCount * .15, 0);

        let lev = 3;
        if(positionCount === (history.length-1)) lev = 1;

        RenderNode(loc, history[positionCount], dataSet, 1, lev);
    }
}

export default RenderHistory;