import Renderer from './renderer';

const LifeCycle = {
    PreRender : () => {
        requestAnimationFrame(Renderer.Render);
        window.canvas.Fade(window.context, context.canvas.width, context.canvas.height, "rgba(255,255,255,1)");
        const now = new Date().getTime();
        state.out.now = now;
        state.in.selections.positions = [];
    },
    PostRender : () => {
        state.sys.anim.frame++;
        state.in.mouse.wheel = 0;
    }
}

export default LifeCycle;